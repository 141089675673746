import React from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import get from 'lodash/get';

import Link from '../../link';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const namespace = 'ui-search-header--seller';
/**
 * @typedef {Object} ShowcaseHeaderProps
 * @property {string} className - Clases CSS adicionales para el componente.
 * @property {Object} logo - Las propiedades del logo.
 * @property {Object} logo.pictures - Las imágenes asociadas al logo.
 * @property {string} logo.pictures.2x - La URL de la imagen en resolución 2x.
 * @property {Object} publications_from - Información sobre las publicaciones.
 * @property {string} publications_from.text - El texto que describe las publicaciones.
 * @property {Object} title - El título del encabezado.
 * @property {string} title.text - El texto del título.
 * @property {Object} [view_more] - Propiedades del enlace "Ver más" (opcional).
 * @property {string} view_more.target - La URL del enlace "Ver más".
 * @property {Object} view_more.label - El texto del enlace "Ver más".
 * @property {string} view_more.label.text - El texto del enlace.
 */

/**
 * Componente que renderiza el encabezado de un showcase con logo, título, publicaciones y un enlace "Ver más".
 *
 * @param {ShowcaseHeaderProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el encabezado del showcase.
 */
const ShowcaseHeader = ({ className = null, title, logo = null, publications_from, view_more = null }) => (
  <div className={classnames(namespace, 'ui-search-header--showcase', className)}>
    {logo && (
      <div className={`${namespace}__logo`}>
        <Image className={`${namespace}__image`} src={get(logo, 'pictures.2x')} lowEnd alt="eshops header" />
      </div>
    )}
    <div className={`${namespace}__info`}>
      <p className={`${namespace}__strapline`}>{publications_from.text}</p>
      <h1 className={`${namespace}__title`}>{title.text}</h1>
      {view_more && (
        <Link className={`${namespace}__action`} href={view_more.target} title={view_more?.label?.text}>
          {view_more.label.text}
        </Link>
      )}
    </div>
  </div>
);

ShowcaseHeader.propTypes = {
  className: string,
  logo: shape({
    pictures: shape({
      '2x': string.isRequired,
    }),
  }),
  publications_from: shape({
    text: string,
  }).isRequired,
  title: shape({
    text: string,
  }).isRequired,
  view_more: shape({
    text: string,
    target: string,
  }),
};

export default withComponentHandler(ShowcaseHeader, { componentName: 'ShowcaseHeader' });
